<template>
    <div class="counseling-view">
      <div class="layout-container">
        <div class="text-section">
          <h1 class="title fade-in">Counseling & Support</h1>
          <p class="fade-in delay-1s">
            Welcome to our GenAI-powered counseling service. Here, you can chat with our friendly AI assistant 
            for mental health support or simply to have a conversation. We're here to listen.
          </p>
        </div>
        <div class="chat-section fade-in delay-2s">
          <div class="chat-box">
            <div v-for="(message, index) in chatMessages" :key="index" :class="['message', message.sender]">
              <span>{{ message.text }}</span>
            </div>
            <div ref="chatEnd"></div>
          </div>
          <div class="input-section">
            <input 
              type="text" 
              v-model="userMessage" 
              placeholder="Type your message here..." 
              @keyup.enter="sendMessage" 
              class="form-control"
            />
            <button @click="sendMessage" class="btn btn-primary">Send</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, nextTick } from 'vue';
  
  export default {
    name: 'CounselingView',
    setup() {
      const chatMessages = ref([{ text: 'Hello! How can I assist you today?', sender: 'ai' }]);
      const userMessage = ref('');
      const chatEnd = ref(null);
  
      const sendMessage = async () => {
        if (userMessage.value.trim() === '') return;
  
        // Add user message to the chat
        chatMessages.value.push({ text: userMessage.value, sender: 'user' });
        const message = userMessage.value;
        userMessage.value = '';
  
        // Send message to AI
        const aiResponse = await getAIResponse(message);
        chatMessages.value.push({ text: aiResponse, sender: 'ai' });
  
        // Scroll to the bottom of the chat
        nextTick(() => {
          chatEnd.value.scrollIntoView({ behavior: 'smooth' });
        });
      };
  
      const getAIResponse = async (message) => {
  const workerUrl = 'https://aiforold.frankran626.workers.dev';

  try {
    const response = await fetch(workerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: [
          { role: 'user', content: message },
        ],
      }),
    });

    if (!response.ok) {
      console.error('API Error:', response.status, await response.text());
      return 'Failed to get a response from the AI.';
    }

    const data = await response.json();
    return data.reply || 'No response from AI.';
  } catch (error) {
    console.error('Error fetching AI response:', error);
    return 'There seems to be an issue with the connection. Please try again later.';
  }
};

  
      return {
        chatMessages,
        userMessage,
        sendMessage,
        chatEnd,
      };
    },
  };
  </script>
  
  
  <style scoped>
  .counseling-view {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    max-width: 1200px;
    margin: auto;
    animation: fadeInUp 0.7s ease-in-out;
  }
  
  .layout-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .chat-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 100%;
  }
  
  .chat-box {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .message {
    padding: 0.5rem 1rem;
    border-radius: 15px;
    margin-bottom: 0.5rem;
    max-width: 70%;
  }
  
  .message.user {
    background-color: #9DC08B;
    color: #fff;
    align-self: flex-end;
  }
  
  .message.ai {
    background-color: #e0e0e0;
    align-self: flex-start;
  }
  
  .input-section {
    display: flex;
    gap: 0.5rem;
  }
  
  input.form-control {
    flex-grow: 1;
    border-radius: 20px;
    padding: 0.5rem 1rem;
  }
  
  .btn-primary {
    background-color: #9DC08B;
    color: #fff;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .btn-primary:hover {
    background-color: #7BA778;
    transform: scale(1.05);
  }
  </style>
  