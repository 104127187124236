<!-- src/App.vue -->
<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <!-- Header -->
    <header>
      <SiteNavbar />
    </header>

    <!-- Main Content Area -->
    <main class="flex-grow-1">
      <router-view></router-view>
    </main>

    <!-- Footer -->
    <footer class="footer text-center py-4">
      <div class="container">
        <p class="mb-0 text-dark-green">© 2024 Jade Wellness Hub. All rights reserved.</p>
        <a class="footer-link" href="#">Terms of Service</a> |
        <a class="footer-link" href="#">Privacy Policy</a>
      </div>
    </footer>
  </div>
</template>

<script>
import SiteNavbar from './components/SiteNavbar.vue';

export default {
  name: 'App',
  components: {
    SiteNavbar,
  },
};
</script>

<style>
/* Global Styles */
body {
  background-color: #EDF1D6; /* Light Green background for the entire app */
  color: #40513B; /* Dark Green text color for contrast */
  font-family: 'Arial', sans-serif;
}

.footer {
  background-color: #9DC08B; /* Soft Green background for footer */
  color: #40513B; /* Dark Green text color */
}

.footer-link {
  color: #40513B; /* Dark Green links in the footer */
  text-decoration: none;
}

.footer-link:hover {
  color: #609966; /* Medium Green hover effect for footer links */
}

.btn-primary {
  background-color: #9DC08B; /* Soft Green for buttons */
  border-color: #9DC08B;
  color: #40513B; /* Dark Green text for buttons */
  border-radius: 25px;
  padding: 10px 20px;
}

.btn-primary:hover {
  background-color: #609966; /* Medium Green on hover */
  color: #EDF1D6; /* Light Green text on hover */
}
</style>
