/* eslint-disable */
<template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';

export default {
  name: 'BarChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    data() {
      this.renderChart();
    },
  },
  methods: {
    renderChart() {
      Chart.register(...registerables);

      const ctx = this.$refs.canvas.getContext('2d');

      // Destroy previous chart instance if it exists to prevent overlapping
      if (this.chart) {
        this.chart.destroy();
      }

      // Process data for the bar chart
      const labels = this.data.map((item) => item.date || 'No Date');
      const feelings = ['Depressed', 'Sad', 'Neutral', 'Happy', 'Very Happy'];
      const datasets = feelings.map((feeling, index) => ({
        label: feeling,
        backgroundColor: this.getColor(index),
        data: this.data.map((item) => {
          const rating = item.feelings.find(f => f.name === feeling)?.rating || 0;
          return rating;
        }),
      }));

      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels,
          datasets,
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Rating (1-5)',
              },
              ticks: {
                stepSize: 1,
                max: 5,
              },
            },
          },
          onClick: (event, elements) => {
            if (elements.length > 0) {
              const index = elements[0].index;
              const clickedDate = this.data[index].date;
              this.$emit('barClicked', clickedDate);
            }
          },
        },
      });
    },
    getColor(index) {
      const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];
      return colors[index % colors.length];
    },
  },
};
</script>

<style scoped>
canvas {
  max-width: 600px;
  margin: auto;
}
</style>
