<!-- src/components/PieChart.vue -->
<template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';

export default {
  name: 'PieChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    setTimeout(() => {
      this.renderChart();
    }, 100); // Adjust the timeout as needed
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        if (newData && newData.length > 0) {
          this.renderChart();
        }
      },
    },
  },
  methods: {
    renderChart() {
      Chart.register(...registerables);

      const canvas = this.$refs.canvas;
      if (!canvas) {
        console.warn('Canvas reference not available yet');
        return;
      }

      const ctx = canvas.getContext('2d');

      // Destroy previous chart instance if it exists to prevent overlapping
      if (this.chart) {
        this.chart.destroy();
      }

      // Create the new chart instance
      this.chart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: this.data.map((item) => item.name),
          datasets: [
            {
              data: this.data.map((item) => item.value),
              backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#4BC0C0',
                '#9966FF',
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
        },
      });
    },
  },
};
</script>

<style scoped>
canvas {
  max-width: 400px;
  margin: auto;
}
</style>
