<template>
  <div class="auth-container container py-5">
    <h2 class="text-dark-green mb-4">Register</h2>
    <transition name="fade">
      <form @submit.prevent="register" class="register-form">
        <!-- Username Field -->
        <transition name="slide-fade">
          <div class="mb-3">
            <label for="username" class="form-label text-dark-green">Username</label>
            <input
              type="text"
              class="form-control"
              id="username"
              v-model="username"
              @blur="validateUsername"
            />
            <div v-if="usernameError" class="text-danger">{{ usernameError }}</div>
          </div>
        </transition>

        <!-- Email Field -->
        <transition name="slide-fade" mode="out-in">
          <div class="mb-3">
            <label for="email" class="form-label text-dark-green">Email address</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="email"
              @blur="validateEmail"
            />
            <div v-if="emailError" class="text-danger">{{ emailError }}</div>
          </div>
        </transition>

        <!-- Password Field -->
        <transition name="slide-fade" mode="out-in">
          <div class="mb-3">
            <label for="password" class="form-label text-dark-green">Password</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="password"
              @blur="validatePassword"
            />
            <div v-if="passwordError" class="text-danger">{{ passwordError }}</div>
          </div>
        </transition>

        <!-- Role Selection -->
        <transition name="slide-fade" mode="out-in">
          <div class="mb-3">
            <label for="role" class="form-label text-dark-green">Role</label>
            <select class="form-select" id="role" v-model="role" @change="validateRole">
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </div>
        </transition>

        <!-- Admin Code Field (conditionally displayed) -->
        <transition name="slide-fade" v-if="role === 'admin'" mode="out-in">
          <div class="mb-3">
            <label for="adminCode" class="form-label text-dark-green">Admin Code</label>
            <input
              type="text"
              class="form-control"
              id="adminCode"
              v-model="adminCode"
              @blur="validateAdminCode"
            />
            <div v-if="adminCodeError" class="text-danger">{{ adminCodeError }}</div>
          </div>
        </transition>

        <!-- Submit Button with Transition -->
        <transition name="fade" mode="out-in">
          <div class="mb-3">
            <button type="submit" class="btn btn-primary" :disabled="isLoading">
              <span v-if="!isLoading">Register</span>
              <span v-else>Loading...</span>
            </button>
          </div>
        </transition>

        <!-- Error Message with Transition -->
        <transition name="fade">
          <div v-if="generalError" class="text-danger mt-2">{{ generalError }}</div>
        </transition>
      </form>
    </transition>

    <!-- Toggle to Login -->
    <p class="mt-3">
      <router-link to="/login" class="text-dark-green">Already have an account? Login here.</router-link>
    </p>
  </div>
</template>

<script>
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';
import { auth, db } from '../firebaseConfig';

export default {
  data() {
    return {
      username: '',
      email: '',
      password: '',
      role: 'user',
      adminCode: '',
      usernameError: '',
      emailError: '',
      passwordError: '',
      adminCodeError: '',
      generalError: '',
      isLoading: false,
    };
  },
  methods: {
    validateUsername() {
      this.usernameError = this.username.trim() ? '' : 'Username is required.';
      if (this.username && !/^[a-zA-Z0-9]+$/.test(this.username)) {
        this.usernameError = 'Username must be alphanumeric.';
      }
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailError = !this.email.trim()
        ? 'Email is required.'
        : !emailPattern.test(this.email)
        ? 'Invalid email format.'
        : '';
    },
    validatePassword() {
      this.passwordError = this.password.length < 6
        ? 'Password must be at least 6 characters long.'
        : '';
    },
    validateAdminCode() {
      if (this.role === 'admin' && this.adminCode !== 'admin') {
        this.adminCodeError = 'Invalid admin code.';
      } else {
        this.adminCodeError = '';
      }
    },
    validateRole() {
      if (this.role !== 'admin') {
        this.adminCode = '';
        this.adminCodeError = '';
      }
    },
    async register() {
      this.isLoading = true;
      this.validateUsername();
      this.validateEmail();
      this.validatePassword();
      this.validateAdminCode();

      if (this.usernameError || this.emailError || this.passwordError || this.adminCodeError) {
        this.isLoading = false;
        return;
      }

      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        await setDoc(doc(db, 'users', user.uid), {
          username: this.username,
          email: this.email,
          role: this.role,
          createdAt: new Date().toISOString(),
        });

        // Send welcome email using the server.js endpoint
        await this.sendWelcomeEmail(this.email, this.username);

        alert('Registration successful! A welcome email has been sent.');
        this.$router.push('/login');
      } catch (error) {
        console.error('Error registering user:', error);
        this.generalError = this.formatFirebaseError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendWelcomeEmail(email, username) {
      try {
        await axios.post('http://localhost:5000/send-email', {
          email,
          username,
        });
        console.log('Welcome email sent successfully');
      } catch (error) {
        console.error('Error sending welcome email:', error);
      }
    },
    formatFirebaseError(error) {
      if (error.code === 'auth/email-already-in-use') {
        return 'This email is already registered. Please use a different email.';
      } else if (error.code === 'auth/weak-password') {
        return 'The password is too weak. Please choose a stronger password.';
      } else {
        return 'An unexpected error occurred. Please try again.';
      }
    },
  },
};
</script>

<style scoped>
.auth-container {
  background-color: #EDF1D6;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
}

.text-dark-green {
  color: #40513B;
}

.form-control {
  border-radius: 5px;
  border: 1px solid #9DC08B;
}

.btn-primary {
  background-color: #9DC08B;
  border-color: #9DC08B;
  color: #40513B;
  border-radius: 25px;
  padding: 10px 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-primary:hover {
  background-color: #609966;
  color: #EDF1D6;
}

.text-danger {
  color: #FF5C5C;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-from {
  opacity: 0;
  transform: translateY(20px);
}
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
