<!-- src/components/UserLogin.vue -->
<template>
  <div class="auth-container container py-5">
    <h2 class="text-dark-green mb-4">Login</h2>
    
    <!-- Loading Animation -->
    <div v-if="isLoading" class="loading-animation">
      <LoadingSpinner />
    </div>

    <!-- Login Form -->
    <form v-else @submit.prevent="login" class="login-form">
      <div class="mb-3">
        <label for="email" class="form-label text-dark-green">Email address</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="email"
        />
      </div>

      <div class="mb-3">
        <label for="password" class="form-label text-dark-green">Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="password"
        />
      </div>

      <button type="submit" class="btn btn-primary">Login</button>

      <div v-if="error" class="text-danger mt-2">{{ error }}</div>
    </form>

    <p class="mt-3">
      <router-link to="/register" class="text-dark-green">Don't have an account? Register here.</router-link>
    </p>
  </div>
</template>

<script>
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: '',
      isLoading: false,
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      this.error = ''; // Reset error message before attempting login

      try {
        console.log('Attempting to log in with:', this.email);
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        // Store authentication state in localStorage
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userEmail', user.email);
        
        console.log('Login successful. Redirecting to home page.');
        this.$router.push('/'); // Redirect to home or dashboard after login
      } catch (error) {
        console.error('Login error:', error);
        // Provide a more user-friendly error message
        if (error.code === 'auth/user-not-found') {
          this.error = 'No user found with this email. Please check your credentials or register.';
        } else if (error.code === 'auth/wrong-password') {
          this.error = 'Incorrect password. Please try again.';
        } else if (error.code === 'auth/invalid-email') {
          this.error = 'Invalid email format. Please enter a valid email.';
        } else {
          this.error = 'Login failed. Please try again later.';
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    LoadingSpinner,
  },
};
</script>



<style scoped>
/* Add any specific styles for UserLogin.vue here */

.auth-container {
  background-color: #EDF1D6; /* Light Green background for the entire form */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-dark-green {
  color: #40513B; /* Dark Green text color */
}

.form-control {
  border-radius: 5px;
  border: 1px solid #9DC08B; /* Soft Green border color */
}

.btn-primary {
  background-color: #9DC08B; /* Soft Green background for button */
  border-color: #9DC08B; /* Soft Green border for button */
  color: #40513B; /* Dark Green text color for button */
  border-radius: 25px;
  padding: 10px 20px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.btn-primary:hover {
  background-color: #609966; /* Medium Green on hover */
  color: #EDF1D6; /* Light Green text on hover */
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
}

.login-form {
  max-width: 400px;
  margin: 0 auto;
}

.text-danger {
  color: #FF5C5C; /* Red color for error messages */
}

.router-link {
  text-decoration: none;
}

.router-link:hover {
  text-decoration: underline;
}
</style>
