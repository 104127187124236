<!-- src/views/HealthResources.vue -->
<template>
  <div class="resources-container container py-5">
    <h2 class="text-dark-green mb-4">Health Resources</h2>
    <p class="text-muted mb-4">Explore a range of health resources designed to empower you with knowledge and support.</p>

    <div class="row">
      <!-- Resource Example 1 -->
      <div class="col-md-4 mb-4">
        <div class="card resource-card shadow-sm">
          <div class="card-body">
            <h5 class="card-title text-dark-green">Healthy Eating Guide</h5>
            <p class="card-text">Discover tips and recipes to maintain a balanced diet.</p>
            <a href="https://www.healthyeating.org" target="_blank" class="btn btn-primary">Read More</a>
          </div>
        </div>
      </div>

      <!-- Resource Example 2 -->
      <div class="col-md-4 mb-4">
        <div class="card resource-card shadow-sm">
          <div class="card-body">
            <h5 class="card-title text-dark-green">Mental Health Support</h5>
            <p class="card-text">Access resources to help you manage stress and mental well-being.</p>
            <a href="https://www.mentalhealth.org" target="_blank" class="btn btn-primary">Learn More</a>
          </div>
        </div>
      </div>

      <!-- Resource Example 3 -->
      <div class="col-md-4 mb-4">
        <div class="card resource-card shadow-sm">
          <div class="card-body">
            <h5 class="card-title text-dark-green">Exercise and Fitness</h5>
            <p class="card-text">Get tips on creating a workout routine that suits your lifestyle.</p>
            <a href="https://www.fitness.org" target="_blank" class="btn btn-primary">Explore</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HealthResources',
};
</script>

<style scoped>
.resources-container {
  background-color: #EDF1D6; /* Light Green background for the resources page */
  padding: 2rem;
  border-radius: 10px;
}

.text-dark-green {
  color: #40513B; /* Dark Green text color */
}

.card.resource-card {
  background-color: #9DC08B; /* Soft Green background for resource cards */
  border: none;
}

.card-title {
  color: #40513B; /* Dark Green for card titles */
}

.card-text {
  color: #40513B; /* Dark Green for card text */
}

.btn-primary {
  background-color: #609966; /* Medium Green for buttons */
  border-color: #609966;
  color: #EDF1D6; /* Light Green text for buttons */
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-primary:hover {
  background-color: #40513B; /* Dark Green on hover */
  color: #EDF1D6; /* Light Green text on hover */
}
</style>
