<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark-green shadow-sm">
    <div class="container">
      <router-link class="navbar-brand d-flex align-items-center" to="/">
        <img src="@/assets/JADE.png" alt="Jade Wellness Hub Logo" class="logo me-2" />
        <span class="site-name">Jade Wellness Hub</span>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About Us</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/health-resources">Health Resources</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/diet">Diet Planner</router-link>
          </li>
          <li class="nav-item" v-if="authState.isAuthenticated">
            <span class="nav-link">Hello, {{ authState.username || 'User' }}!</span>
          </li>
          <li class="nav-item" v-if="authState.isAuthenticated && authState.role === 'admin'">
            <router-link class="nav-link" to="/admin-dashboard">Admin Dashboard</router-link>
          </li>
          <li class="nav-item" v-if="authState.isAuthenticated">
            <router-link class="nav-link" to="/profile">Profile</router-link>
          </li>
          <li class="nav-item" v-if="!authState.isAuthenticated">
            <router-link class="nav-link" to="/login">Login</router-link>
          </li>
          <li class="nav-item" v-if="!authState.isAuthenticated">
            <router-link class="nav-link" to="/register">Register</router-link>
          </li>
          <li class="nav-item" v-if="authState.isAuthenticated">
            <a class="nav-link" href="#" @click.prevent="handleLogout">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted } from 'vue';
import { auth, db } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

export default {
  name: 'SiteNavbar',
  setup() {
    const authState = ref({
      isAuthenticated: false,
      username: null,
      role: null,
    });

    const fetchUserData = async (userId) => {
      try {
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          authState.value.username = userData.username;
          authState.value.role = userData.role;
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const updateAuthState = (user) => {
      if (user) {
        authState.value.isAuthenticated = true;
        fetchUserData(user.uid);
      } else {
        authState.value.isAuthenticated = false;
        authState.value.username = null;
        authState.value.role = null;
      }
    };

    onMounted(() => {
      onAuthStateChanged(auth, (user) => {
        updateAuthState(user);
      });
    });

    const handleLogout = async () => {
      try {
        await auth.signOut();
        authState.value.isAuthenticated = false;
        authState.value.username = null;
        authState.value.role = null;
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    return {
      authState,
      handleLogout,
    };
  },
};
</script>

<style scoped>
.navbar {
  padding: 1rem 0;
  background-color: #40513B; /* Dark Green background for navbar */
}

.logo {
  max-height: 50px; /* Adjust logo size for better visibility */
  width: auto; /* Maintain aspect ratio */
}

.site-name {
  font-size: 1.8rem;
  font-weight: bold;
  color: #EDF1D6; /* Light Green text color for site name */
  margin-left: 10px; /* Space between logo and site name */
}

.nav-link {
  font-size: 1rem;
  font-weight: 600;
  color: #EDF1D6; /* Light Green color for nav links */
  margin-right: 15px; /* Spacing between nav links */
  transition: color 0.3s ease; /* Smooth color transition */
}

.nav-link:hover {
  color: #9DC08B; /* Soft Green on hover */
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  filter: invert(1); /* Light color for toggler icon to match dark background */
}
</style>
