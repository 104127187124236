<template>
  <div class="feeling-view">
    <div class="layout-container">
      <div class="text-section fade-in">
        <h1 class="title">How Are You Today?</h1>
        <p class="fade-in delay-1s">
          At the Jade Wellness Hub, we care about how you feel every day.
          Here, you can reflect on your emotions, track your mood over time,
          and see helpful insights to better understand yourself.
          <br /><br />
          Use this tool to monitor your feelings, look back at previous days, and see how you’ve been feeling over the past week.
          Let’s take a moment to reflect and start your journey to well-being.
        </p>
      </div>
      <div class="image-section fade-in delay-1s">
        <img src="@/assets/feeling.jpg" alt="Feeling" class="feeling-image" />
      </div>
    </div>

    <div v-if="isLoading" class="loading slide-in">Loading your data...</div>
    <div v-if="error" class="error-message fade-in">{{ error }}</div>

    <div v-else>
      <h2 class="chart-title fade-in delay-1s">Your Weekly Mood Overview</h2>
      <p class="chart-description fade-in delay-1s">
        The bar chart below shows a summary of your daily feelings over the past week. Each bar represents a day, and the height of each section indicates the intensity of each emotion.
        Click on a bar to see a detailed breakdown of how you felt on that day.
      </p>
      <BarChart @barClicked="showPieChart" :data="filteredWeeklySentimentsData" />

      <transition name="fade">
        <div v-if="selectedDayData" class="popup zoom-in">
          <h3>Feelings on {{ selectedDay }}</h3>
          <p class="chart-description fade-in">
            Here is a breakdown of your feelings on <strong>{{ selectedDay }}</strong>. The pie chart represents the relative intensity of each emotion for that day.
          </p>
          <PieChart :data="selectedDayData" />
          <p v-if="userNote" class="fade-in">Personal note: "{{ userNote }}"</p>
          <button @click="closePieChart" class="btn btn-secondary">Close</button>
        </div>
      </transition>

      <h3 class="mt-5 fade-in delay-2s">Would you like to share how you're feeling today?</h3>
      <p class="fade-in delay-2s">
        Reflecting on your emotions can help you understand your mood better.
        Click below to begin and let us know how you're feeling today.
      </p>
      <button @click="showQuestionnaire" class="btn btn-primary fade-in delay-3s">Start Today's Reflection</button>

      <div v-if="showingQuestionnaire" class="zoom-in">
        <h4>How are you feeling today? Select up to 3 emotions.</h4>
        <div class="feelings-options">
          <label v-for="feeling in availableFeelings" :key="feeling">
            <input 
              type="checkbox" 
              :value="feeling" 
              v-model="selectedFeelings" 
              :disabled="selectedFeelings.length >= 3 && !selectedFeelings.includes(feeling)"
            />
            {{ feeling }}
          </label>
        </div>
        
        <h4 class="mt-4">Would you like to share more about why you're feeling this way?</h4>
        <textarea 
          v-model="userInput" 
          placeholder="It's optional, but feel free to express your thoughts."
          rows="4"
          class="form-control"
        ></textarea>
        
        <button @click="submitFeelings" class="btn btn-primary mt-3">Submit</button>
      </div>
    </div>
  </div>
</template>


<script>
import BarChart from '@/components/BarChart.vue';
import PieChart from '@/components/PieChart.vue';
import { db, auth } from '../firebaseConfig';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';

export default {
  name: 'FeelingView',
  components: {
    BarChart,
    PieChart,
  },
  data() {
    return {
      weeklySentimentsData: [],
      selectedDayData: null,
      selectedDay: '',
      userNote: '',
      availableFeelings: ['Depressed', 'Sad', 'Neutral', 'Happy', 'Very Happy'],
      selectedFeelings: [],
      userInput: '',
      showingQuestionnaire: false,
      isLoading: true,
      error: null,
    };
  },
  computed: {
    filteredWeeklySentimentsData() {
      return this.weeklySentimentsData.filter((entry) => entry.feelings.length > 0);
    },
  },
  methods: {
    async loadWeeklySentiments() {
      try {
        const user = auth.currentUser;
        if (user) {
          const q = query(
            collection(db, 'sentiments'),
            where('userId', '==', user.uid)
          );
          const querySnapshot = await getDocs(q);
          this.weeklySentimentsData = this.processData(querySnapshot);
        }
      } catch (error) {
        console.error('Error loading sentiment data:', error);
        this.error = 'Failed to load your data. Please try again later.';
      } finally {
        this.isLoading = false;
      }
    },
    processData(querySnapshot) {
      const data = [];
      querySnapshot.forEach((doc) => {
        const record = doc.data();
        data.push({
          date: record.date,
          feelings: record.feelings,
          note: record.note,
          details: record.feelings.reduce((acc, feeling) => {
            acc[feeling.name] = feeling.rating;
            return acc;
          }, {}),
        });
      });
      return data;
    },
    showPieChart(day) {
      const dayData = this.weeklySentimentsData.find(d => d.date === day);
      if (dayData) {
        this.selectedDay = day;
        this.selectedDayData = Object.entries(dayData.details).map(([feeling, rating]) => ({
          name: feeling,
          value: rating,
        }));
        this.userNote = dayData.note || '';
      }
    },
    closePieChart() {
      this.selectedDayData = null;
    },
    showQuestionnaire() {
      this.showingQuestionnaire = true;
    },
    async submitFeelings() {
      if (this.selectedFeelings.length === 0) {
        alert('Please select at least one feeling.');
        return;
      }
      const user = auth.currentUser;
      if (user) {
        try {
          const existingEntryQuery = query(
            collection(db, 'sentiments'),
            where('userId', '==', user.uid),
            where('date', '==', new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric' }))
          );
          const querySnapshot = await getDocs(existingEntryQuery);

          if (!querySnapshot.empty) {
            alert('You have already submitted your feelings for today.');
            return;
          }

          const feelingsData = this.selectedFeelings.map(feeling => ({
            name: feeling,
            rating: this.getFeelingRating(feeling),
          }));

          await addDoc(collection(db, 'sentiments'), {
            userId: user.uid,
            date: new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
            feelings: feelingsData,
            note: this.userInput,
            createdAt: new Date(),
          });
          alert('Thank you for sharing your feelings today!');
          this.resetForm();
          this.loadWeeklySentiments();
        } catch (error) {
          console.error('Error submitting feelings:', error);
          this.error = 'Failed to record your feelings. Please try again later.';
        }
      } else {
        alert('Please log in to record your feelings.');
      }
    },
    getFeelingRating(feeling) {
      const ratings = {
        'Depressed': 1,
        'Sad': 2,
        'Neutral': 3,
        'Happy': 4,
        'Very Happy': 5,
      };
      return ratings[feeling] || 3;
    },
    resetForm() {
      this.selectedFeelings = [];
      this.userInput = '';
      this.showingQuestionnaire = false;
    },
  },
  mounted() {
    this.loadWeeklySentiments();
  },
};
</script>

<style scoped>
.feeling-view {
  padding: 4rem 2rem; /* Increased padding for a more spacious look */
  background-color: #f9f9f9;
  border-radius: 10px;
  max-width: 1400px; /* Wider for a more impactful hero section */
  margin: auto;
  animation: fadeInUp 0.7s ease-in-out;
}

.layout-container {
  display: flex;
  align-items: center;
  gap: 3rem; /* Increased gap for more breathing room between text and image */
  flex-wrap: wrap; /* Wrap to ensure responsiveness */
}

.text-section {
  flex: 1;
  min-width: 300px; /* Ensures text section doesn't shrink too much */
}

.title {
  font-size: 4rem; /* Bigger title for a more prominent hero section */
  font-weight: bold;
  margin-bottom: 1.5rem; /* Increased space below the title */
  color: #3a3a3a;
}

.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px; /* Ensures image section doesn't shrink too much */
}

.feeling-image {
  max-width: 100%;
  height: auto;
  border-radius: 20px; /* Slightly rounder corners for a softer look */
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}

.loading, .error-message {
  text-align: center;
  margin-top: 2rem;
  color: #609966;
  font-size: 1.2rem;
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.fade-in.delay-1s {
  animation: fadeIn 1s ease-in-out 0.5s;
}

.fade-in.delay-2s {
  animation: fadeIn 1s ease-in-out 1s;
}

.fade-in.delay-3s {
  animation: fadeIn 1s ease-in-out 1.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.popup {
  animation: zoomIn 0.5s ease-in-out;
}

.btn-primary {
  background-color: #9DC08B;
  color: #fff;
  border-radius: 20px;
  padding: 0.75rem 1.5rem; /* Increased padding for more prominence */
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 1rem; /* Increased font size for better readability */
}

.btn-primary:hover {
  background-color: #7BA778;
  transform: scale(1.05);
}

.btn-secondary {
  background-color: #e74c3c;
  color: #fff;
  border-radius: 20px;
  padding: 0.75rem 1.5rem; /* Consistent with primary button */
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 1rem;
  font-size: 1rem;
}

.btn-secondary:hover {
  background-color: #d84333;
  transform: scale(1.05);
}

.feelings-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
}

.feelings-options label {
  background-color: #9DC08B;
  padding: 0.75rem 1.25rem; /* Increased padding for a more clickable feel */
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.feelings-options label:hover {
  background-color: #7BA778;
  transform: scale(1.05);
}

textarea {
  width: 100%;
  margin-top: 1rem;
  transition: box-shadow 0.2s ease;
  border-radius: 10px; /* Rounded corners for a softer look */
  padding: 0.75rem;
}

textarea:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chart-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 2rem 0 1rem;
  text-align: center;
  color: #3a3a3a;
}

.chart-description {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #4f4f4f;
}
</style>
