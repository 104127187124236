import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AboutUs from '../views/AboutUs.vue';
import UserLogin from '../components/UserLogin.vue';
import UserRegister from '../components/UserRegister.vue';
import UserDashboard from '../views/UserDashboard.vue';
import UserProfile from '../views/UserProfile.vue';
import HealthResources from '../views/HealthResources.vue';
import DietPlanner from '../views/DietPlanner.vue';
import ElderlyHomeMap from '../views/ElderlyHomeMap.vue';
import BookingView from '../views/BookingView.vue'; // Corrected import path for BookingView
import FeelingView from '../views/FeelingView.vue'; // Import the FeelingView component
import CounselingView from '../views/CounselingView.vue'; // Import the CounselingView component

const routes = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/about', name: 'AboutUs', component: AboutUs },
  { path: '/login', name: 'Login', component: UserLogin },
  { path: '/register', name: 'Register', component: UserRegister },
  {
    path: '/admin-dashboard',
    name: 'AdminDashboard',
    component: () => import('../views/AdminDashboard.vue'),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/user-dashboard',
    name: 'UserDashboard',
    component: UserDashboard,
    meta: { requiresAuth: true, role: 'user' },
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/health-resources',
    name: 'HealthResources',
    component: HealthResources,
  },
  {
    path: '/diet',
    name: 'DietPlanner',
    component: DietPlanner,
  },
  {
    path: '/map',
    name: 'ElderlyHomeMap',
    component: ElderlyHomeMap,
  },
  {
    path: '/booking',
    name: 'BookingView',
    component: BookingView,
    meta: { requiresAuth: true },
  },
  {
    path: '/feeling',
    name: 'FeelingView',
    component: FeelingView,
    meta: { requiresAuth: true }, // Requires user to be logged in to access
  },
  {
    path: '/counseling',
    name: 'CounselingView',
    component: CounselingView,
    meta: { requiresAuth: true }, // Requires user to be logged in to access
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard for protected routes
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const userRole = localStorage.getItem('userRole');

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'Login' });
  } else if (to.meta.role && to.meta.role !== userRole) {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;
