<template>
  <div class="diet-container">
    <h2 class="text-dark-green mb-4">Diet Planner</h2>

    <div v-if="isLoading" class="loading">Loading data...</div>
    <div v-if="error" class="error-message">{{ error }}</div>

    <div v-else>
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search foods..."
        class="form-control mb-3"
      />

      <!-- Food table -->
      <table class="table">
        <thead>
          <tr>
            <th @click="sortBy('food')">Food Name</th>
            <th @click="sortBy('Caloric Value')">Calories</th>
            <th @click="sortBy('Carbohydrates')">Carbohydrates (g)</th>
            <th @click="sortBy('Fat')">Fat (g)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="food in paginatedFoods" :key="food.id">
            <td>{{ food.food }}</td>
            <td>{{ food['Caloric Value'] }}</td>
            <td>{{ food.Carbohydrates }}</td>
            <td>{{ food.Fat }}</td>
            <td>
              <button class="btn btn-primary" @click="addToMenu(food)">
                Add to Today's Menu
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination-controls">
        <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>

      <!-- Today's Menu table -->
      <h3 class="mt-5">Today's Menu</h3>
      <table class="table">
        <thead>
          <tr>
            <th>Food Name</th>
            <th>Calories</th>
            <th>Carbohydrates (g)</th>
            <th>Fat (g)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="menuItem in todaysMenu" :key="menuItem.id">
            <td>{{ menuItem.food }}</td>
            <td>{{ menuItem['Caloric Value'] }}</td>
            <td>{{ menuItem.Carbohydrates }}</td>
            <td>{{ menuItem.Fat }}</td>
            <td>
              <button class="btn btn-danger" @click="removeFromMenu(menuItem.id)">
                Remove
              </button>
            </td>
          </tr>
          <!-- Total row -->
          <tr class="table-total">
            <td><strong>Total</strong></td>
            <td>{{ totalCalories }}</td>
            <td>{{ totalCarbohydrates }}</td>
            <td>{{ totalFat }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <!-- Export to CSV Button -->
      <button class="btn btn-success mt-4" @click="exportToCSV">
        Export Menu to CSV
      </button>
    </div>
  </div>
</template>

<script>
import { getDocs, collection, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

export default {
  name: 'DietPlanner',
  data() {
    return {
      foods: [],
      todaysMenu: [],
      isLoading: true,
      error: null,
      searchQuery: '',
      sortKey: '',
      sortAsc: true,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    filteredFoods() {
      return this.foods.filter(
        (food) =>
          food.food &&
          food.food.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    sortedFoods() {
      return [...this.filteredFoods].sort((a, b) => {
        if (!this.sortKey) return 0;
        const result =
          a[this.sortKey] > b[this.sortKey] ? 1 : a[this.sortKey] < b[this.sortKey] ? -1 : 0;
        return this.sortAsc ? result : -result;
      });
    },
    paginatedFoods() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedFoods.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredFoods.length / this.itemsPerPage);
    },
    totalCalories() {
      return this.todaysMenu
        .reduce(
          (total, item) => total + parseFloat(item['Caloric Value'] || 0),
          0
        )
        .toFixed(2);
    },
    totalCarbohydrates() {
      return this.todaysMenu
        .reduce(
          (total, item) => total + parseFloat(item.Carbohydrates || 0),
          0
        )
        .toFixed(2);
    },
    totalFat() {
      return this.todaysMenu
        .reduce(
          (total, item) => total + parseFloat(item.Fat || 0),
          0
        )
        .toFixed(2);
    },
  },
  methods: {
    async fetchFoods() {
      try {
        const querySnapshot = await getDocs(collection(db, 'foods'));
        this.foods = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        this.fetchTodaysMenu();
      } catch (error) {
        console.error('Error fetching foods:', error);
        this.error = 'Failed to load data. Please try again later.';
      } finally {
        this.isLoading = false;
      }
    },
    async fetchTodaysMenu() {
      try {
        const user = auth.currentUser;
        if (user) {
          const querySnapshot = await getDocs(
            collection(db, `users/${user.uid}/todaysMenu`)
          );
          this.todaysMenu = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        }
      } catch (error) {
        console.error("Error fetching today's menu:", error);
        this.error = "Failed to load today's menu.";
      }
    },
    async addToMenu(food) {
      try {
        const user = auth.currentUser;
        if (user) {
          await addDoc(collection(db, `users/${user.uid}/todaysMenu`), {
            food: food.food,
            'Caloric Value': food['Caloric Value'],
            Carbohydrates: food.Carbohydrates,
            Fat: food.Fat,
            createdAt: new Date(),
          });
          this.fetchTodaysMenu();
          alert(`Added ${food.food} to your menu for today.`);
        } else {
          alert('Please log in to add items to your menu.');
        }
      } catch (error) {
        console.error('Error adding to menu:', error);
        this.error = 'Failed to add item to menu.';
      }
    },
    async removeFromMenu(menuItemId) {
      try {
        const user = auth.currentUser;
        if (user) {
          await deleteDoc(doc(db, `users/${user.uid}/todaysMenu`, menuItemId));
          this.fetchTodaysMenu();
          alert('Item removed from today\'s menu.');
        }
      } catch (error) {
        console.error('Error removing item from menu:', error);
        this.error = 'Failed to remove item from menu.';
      }
    },
    sortBy(key) {
      this.sortAsc = this.sortKey === key ? !this.sortAsc : true;
      this.sortKey = key;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    exportToCSV() {
      // Define the CSV header
      const headers = ['Food Name', 'Calories', 'Carbohydrates (g)', 'Fat (g)'];
      
      // Map the data to CSV rows
      const rows = this.todaysMenu.map(item => [
        item.food,
        item['Caloric Value'],
        item.Carbohydrates,
        item.Fat
      ]);

      // Combine headers and rows into a CSV string
      let csvContent = 'data:text/csv;charset=utf-8,'
        + headers.join(',') + '\n'
        + rows.map(row => row.join(',')).join('\n');

      // Create a link element to trigger the download
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'todays_menu.csv');
      document.body.appendChild(link);
      
      // Trigger the download
      link.click();
      
      // Clean up by removing the link element
      document.body.removeChild(link);
    },
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.fetchFoods();
      } else {
        this.isLoading = false;
        this.error = 'Please log in to access the diet planner.';
      }
    });
  },
};
</script>

<style scoped>
.diet-container {
  background-color: #EDF1D6;
  padding: 2rem;
  border-radius: 10px;
}

.table {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th {
  background-color: #9DC08B;
  color: #40513B;
  cursor: pointer;
}

th, td {
  padding: 12px;
  border: 1px solid #ddd;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.btn-primary {
  background-color: #9DC08B;
  color: #fff;
  border-radius: 25px;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #609966;
}

.btn-danger {
  background-color: #e74c3c;
  color: #fff;
  border-radius: 25px;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.btn-danger:hover {
  background-color: #c0392b;
}

.btn-success {
  background-color: #28a745;
  color: #fff;
  border-radius: 25px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
  display: block;
  margin: 0 auto;
}

.btn-success:hover {
  background-color: #218838;
}

.loading {
  text-align: center;
  color: #609966;
  margin-bottom: 20px;
}

.error-message {
  text-align: center;
  color: #ff0000;
  margin-bottom: 20px;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 2rem;
}
</style>
