// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { reactive } from 'vue';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDeT1byyoU3w5boFnbgosVOrHCAclDMWQ0",
  authDomain: "health-charity-webapp.firebaseapp.com",
  projectId: "health-charity-webapp",
  storageBucket: "health-charity-webapp.appspot.com",
  messagingSenderId: "744109288265",
  appId: "1:744109288265:web:291c38af3b2384f4980898",
  measurementId: "G-ZER95J379V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Reactive object to store user information
const userState = reactive({
  user: null,
  username: null,
});

// Listen to authentication state changes
onAuthStateChanged(auth, async (user) => {
  if (user) {
    userState.user = user;
    // Fetch user data from Firestore if available
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      userState.username = userDoc.data().username;
    }
  } else {
    userState.user = null;
    userState.username = null;
  }
});

export { auth, db, userState };
