<template>
  <div class="profile-container container py-5">
    <h2 class="text-dark-green mb-4">User Profile</h2>
    <div class="profile-card">
      <div class="profile-header">
        <h3>{{ user.username }}</h3>
        <p>{{ user.email }}</p>
        <span class="badge">{{ user.role }}</span>
      </div>
      <div class="profile-body">
        <h4 class="text-dark-green">Update Profile Information</h4>
        <form @submit.prevent="updateProfile">
          <div class="mb-3">
            <label for="username" class="form-label text-dark-green">Username</label>
            <input
              type="text"
              class="form-control"
              id="username"
              v-model="form.username"
            />
          </div>

          <div class="mb-3">
            <label for="email" class="form-label text-dark-green">Email address</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="form.email"
              disabled
            />
          </div>

          <button type="submit" class="btn btn-primary">Save Changes</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export default {
  data() {
    return {
      user: {
        username: '',
        email: '',
        role: 'user',
      },
      form: {
        username: '',
        email: '',
      },
    };
  },
  async mounted() {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        this.user.username = userData.username;
        this.user.email = userData.email;
        this.user.role = userData.role;

        // Initialize form fields with current user data
        this.form.username = userData.username;
        this.form.email = userData.email;
      }
    }
  },
  methods: {
    async updateProfile() {
      try {
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          await updateDoc(userRef, {
            username: this.form.username,
          });

          // Update local user data after successful update
          this.user.username = this.form.username;
          alert('Profile updated successfully.');
          window.dispatchEvent(new Event('authStateChanged')); // Trigger an event to update the navbar
        }
      } catch (error) {
        console.error('Error updating profile:', error);
        alert('Failed to update profile. Please try again.');
      }
    },
  },
};
</script>

  <style scoped>
  .profile-container {
    background-color: #EDF1D6; /* Light Green background */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto; /* Center the profile container */
  }
  
  .text-dark-green {
    color: #40513B; /* Dark Green text */
  }
  
  .profile-card {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  }
  
  .profile-header {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .profile-header h3 {
    color: #40513B; /* Dark Green */
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .profile-header p {
    color: #609966; /* Medium Green */
    margin-bottom: 0.5rem;
  }
  
  .badge {
    background-color: #9DC08B; /* Soft Green */
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    font-size: 0.9rem;
  }
  
  .profile-body {
    border-top: 1px solid #9DC08B; /* Soft Green border */
    padding-top: 1.5rem;
  }
  
  .form-control {
    border-radius: 5px;
    border: 1px solid #9DC08B; /* Soft Green border */
  }
  
  .btn-primary {
    background-color: #9DC08B; /* Soft Green button */
    border-color: #9DC08B; /* Soft Green border */
    color: #40513B; /* Dark Green text */
    border-radius: 25px;
    padding: 10px 20px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  }
  
  .btn-primary:hover {
    background-color: #609966; /* Medium Green on hover */
    color: #EDF1D6; /* Light Green text on hover */
  }
  </style>
  