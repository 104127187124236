<template>
    <div class="booking-container">
      <h2 class="text-dark-green mb-4">Book a Counseling Session</h2>
      <p>Select a Time Slot for Next Week</p>
  
      <div v-if="isLoading" class="loading">Loading available slots...</div>
      <div v-if="error" class="error-message">{{ error }}</div>
  
      <div v-else>
        <table class="table">
          <thead>
            <tr>
              <th>Time</th>
              <th v-for="(date, index) in nextWeekDates" :key="index">{{ date }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(time, index) in times" :key="index">
              <td>{{ time }}</td>
              <td v-for="(date, dateIndex) in nextWeekDates" :key="dateIndex">
                <button
                  class="btn btn-primary"
                  :disabled="isSlotBooked(date, time)"
                  @click="bookSlot(date, time)"
                >
                  {{ isSlotBooked(date, time) ? 'Booked' : 'Book' }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <h3 class="mt-5">Your Booked Sessions</h3>
        <div v-if="userBookings.length === 0">No sessions booked yet.</div>
        <ul>
          <li v-for="booking in userBookings" :key="booking.id">
            {{ booking.date }} - {{ booking.time }}
            <button class="btn btn-danger ml-2" @click="cancelBooking(booking.id)">
              Cancel
            </button>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import { collection, addDoc, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
  import { db, auth } from '../firebaseConfig';
  import { onAuthStateChanged } from 'firebase/auth';
  
  export default {
    name: 'BookingView',
    data() {
      return {
        nextWeekDates: [],
        times: ['09:00 - 10:00', '10:00 - 11:00', '11:00 - 12:00', '01:00 - 02:00', '02:00 - 03:00'],
        userBookings: [],
        allBookings: [],
        isLoading: true,
        error: null,
        isLoggedIn: false,
      };
    },
    methods: {
      generateNextWeekDates() {
        const startDate = new Date();
        const dates = [];
        for (let i = 1; i <= 7; i++) {
          const date = new Date(startDate);
          date.setDate(startDate.getDate() + i);
          dates.push(date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }));
        }
        this.nextWeekDates = dates;
        this.isLoading = false; // Set loading to false after generating dates
      },
      async fetchBookings() {
        try {
          const bookingsRef = collection(db, 'bookings');
          onSnapshot(bookingsRef, (snapshot) => {
            this.allBookings = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
  
            // Filter bookings specific to the logged-in user for display in "Your Booked Sessions"
            const user = auth.currentUser;
            this.userBookings = this.allBookings.filter((booking) => booking.userId === user?.uid);
  
            this.isLoading = false; // Set loading to false once bookings are loaded
          });
        } catch (error) {
          console.error('Error fetching bookings:', error);
          this.error = 'Failed to load bookings.';
          this.isLoading = false;
        }
      },
      isSlotBooked(date, time) {
        return this.allBookings.some(
          (booking) => booking.date === date && booking.time === time
        );
      },
      async bookSlot(date, time) {
        try {
          if (this.isSlotBooked(date, time)) {
            alert('This time slot is already booked.');
            return;
          }
  
          const user = auth.currentUser;
          if (user) {
            await addDoc(collection(db, 'bookings'), {
              userId: user.uid,
              date,
              time,
              createdAt: new Date(),
            });
            alert(`Successfully booked the session on ${date} at ${time}.`);
          } else {
            alert('Please log in to book a slot.');
          }
        } catch (error) {
          console.error('Error booking slot:', error);
          this.error = 'Failed to book the slot. Please try again later.';
        }
      },
      async cancelBooking(bookingId) {
        try {
          const user = auth.currentUser;
          if (user) {
            await deleteDoc(doc(db, 'bookings', bookingId));
            alert('Your booking has been successfully canceled.');
          }
        } catch (error) {
          console.error('Error canceling booking:', error);
          this.error = 'Failed to cancel the booking. Please try again later.';
        }
      },
    },
    mounted() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.isLoggedIn = true;
          this.isLoading = true; // Start with loading true
          this.generateNextWeekDates();
          this.fetchBookings();
        } else {
          this.isLoggedIn = false;
          this.isLoading = false;
          this.error = 'Please log in to access booking functionality.';
        }
      });
    },
  };
  </script>
  
 
  
  <style scoped>
  .booking-container {
    background-color: #EDF1D6;
    padding: 2rem;
    border-radius: 10px;
  }
  
  .table {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  th {
    background-color: #9DC08B;
    color: #40513B;
  }
  
  td {
    background-color: #F7F7F7;
  }
  
  .btn-primary {
    background-color: #9DC08B;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:disabled {
    background-color: #C8C8C8;
    cursor: not-allowed;
  }
  
  .btn-danger {
    background-color: #e74c3c;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 5px;
    transition: background-color 0.3s ease;
  }
  
  .btn-danger:hover {
    background-color: #c0392b;
  }
  
  .loading {
    text-align: center;
    color: #609966;
    margin-bottom: 20px;
  }
  
  .error-message {
    text-align: center;
    color: #ff0000;
    margin-bottom: 20px;
  }
  
  .mt-5 {
    margin-top: 2rem;
  }
  </style>
  