<!-- src/views/UserDashboard.vue -->
<template>
    <div class="user-dashboard container mt-5">
      <h2 class="mb-4 text-beige">User Dashboard</h2>
      
      <div class="row">
        <!-- User Information Section -->
        <div class="col-md-4 mb-4">
          <div class="card bg-medium-green text-beige shadow-sm">
            <div class="card-body">
              <h5 class="card-title">Your Information</h5>
              <p class="card-text">View and update your personal information, including contact details and preferences.</p>
              <button class="btn btn-beige">Manage Profile</button>
            </div>
          </div>
        </div>
  
        <!-- Recent Activities Section -->
        <div class="col-md-4 mb-4">
          <div class="card bg-medium-green text-beige shadow-sm">
            <div class="card-body">
              <h5 class="card-title">Recent Activities</h5>
              <p class="card-text">Check your recent interactions, downloads, and updates on health resources.</p>
              <button class="btn btn-beige">View Activities</button>
            </div>
          </div>
        </div>
  
        <!-- Key Resources Section -->
        <div class="col-md-4 mb-4">
          <div class="card bg-medium-green text-beige shadow-sm">
            <div class="card-body">
              <h5 class="card-title">Health Resources</h5>
              <p class="card-text">Access recommended health articles, guides, and other valuable resources.</p>
              <button class="btn btn-beige">Go to Resources</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UserDashboard',
  };
  </script>
  
  <style scoped>
  .user-dashboard {
    background-color: #1A3636; /* Dark Green background */
    padding: 2rem;
    border-radius: 8px;
  }
  
  .card {
    background-color: #40534C; /* Medium Green for cards */
    border: none;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .btn-beige {
    background-color: #D6BD98; /* Beige button color */
    color: #1A3636; /* Dark Green text for buttons */
    border: none;
    border-radius: 20px;
  }
  
  .btn-beige:hover {
    background-color: #677D6A; /* Light Green on hover */
    color: #D6BD98; /* Beige text on hover */
  }
  </style>
  