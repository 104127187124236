<template>
  <div class="home-page">
    <!-- Hero Section -->
    <section class="hero-section position-relative" data-aos="fade-in">
      <img src="@/assets/HomePage.png" alt="Hero Image" class="hero-image" />
      <div class="hero-overlay">
        <div class="container text-center text-white">
          <h1 class="hero-title" data-aos="fade-up">Welcome to Jade Wellness Hub</h1>
          <p class="hero-subtitle" data-aos="fade-up" data-aos-delay="200">
            Empowering communities with health resources and support
          </p>
          <router-link to="/about" class="btn btn-primary btn-lg mt-4" data-aos="zoom-in" data-aos-delay="400">
            Learn More
          </router-link>
        </div>
      </div>
    </section>

    <!-- Additional Home Page Content -->
    <section class="additional-content py-5" data-aos="fade-up">
      <div class="container">
        <h2 class="section-title text-center mb-4" data-aos="fade-down">
          Explore Our Services
        </h2>
        <div class="row">
          <!-- Example Service 1: Book a Counseling Session -->
          <div class="col-md-3 mb-4" data-aos="fade-up" data-aos-delay="100">
            <div class="card bg-light-green text-dark-green shadow-sm">
              <div class="card-body">
                <h5 class="card-title">Book a Counseling Session</h5>
                <p class="card-text">
                  Book an online mental health counseling session tailored for elderly care. Select an available time slot for the next week.
                </p>
                <router-link to="/booking" class="btn btn-outline-primary">
                  Book Now
                </router-link>
              </div>
            </div>
          </div>
          <!-- Updated Example Service 2: Daily Sentiment Tracker -->
          <div class="col-md-3 mb-4" data-aos="fade-up" data-aos-delay="200">
            <div class="card bg-light-green text-dark-green shadow-sm">
              <div class="card-body">
                <h5 class="card-title">Track Your Daily Feelings</h5>
                <p class="card-text">
                  Reflect on how you feel each day, and explore insights through interactive charts. Share your feelings and get personalized feedback.
                </p>
                <router-link to="/feeling" class="btn btn-outline-primary">
                  Record Feelings
                </router-link>
              </div>
            </div>
          </div>
          <!-- Example Service 3: Nearby Elderly Care Facilities -->
          <div class="col-md-3 mb-4" data-aos="fade-up" data-aos-delay="300">
            <div class="card bg-light-green text-dark-green shadow-sm">
              <div class="card-body">
                <h5 class="card-title">Nearby Elderly Care Facilities</h5>
                <p class="card-text">Find nearby elderly care facilities and services to support your loved ones.</p>
                <router-link to="/map" class="btn btn-outline-primary">View Map</router-link>
              </div>
            </div>
          </div>
          <!-- New Service: Counseling & Support -->
          <div class="col-md-3 mb-4" data-aos="fade-up" data-aos-delay="400">
            <div class="card bg-light-green text-dark-green shadow-sm">
              <div class="card-body">
                <h5 class="card-title">Counseling & Support</h5>
                <p class="card-text">
                  Chat with our GenAI-powered assistant for mental health support or to simply have a conversation. We’re here to listen.
                </p>
                <router-link to="/counseling" class="btn btn-outline-primary">
                  Chat Now
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- Rating Component -->
        <div class="rating-section mt-5" data-aos="fade-up" data-aos-delay="500">
          <h2 class="section-title text-center mb-4">Rate Our Services</h2>
          <Rating />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import Rating from '@/components/ServiceRating.vue';

export default {
  name: 'HomePage',
  components: {
    Rating,
  },
  mounted() {
    AOS.init({
      duration: 1000, // Animation duration (1000ms = 1s)
      offset: 200, // Offset for animations (triggers animations sooner or later based on scroll)
      easing: 'ease-in-out', // Easing for animations
    });
  },
};
</script>

<style scoped>
/* Overall Page Background */
.home-page {
  background-color: #1A3636; /* Dark Green background for the entire page */
  min-height: 100vh;
}

/* Hero Section Styling */
.hero-section {
  position: relative;
  width: 100%;
  height: 70vh; /* Adjust height as needed */
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the hero section without distortion */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for text contrast */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: bold;
  color: #D6BD98; /* Beige color for text */
}

.hero-subtitle {
  font-size: 1.2rem;
  color: #D6BD98; /* Beige color for text */
}

/* Additional Content Section */
.additional-content {
  background-color: #1A3636; /* Dark Green background for continuity */
  color: #D6BD98; /* Beige text color */
}

.section-title {
  color: #D6BD98; /* Beige color for the section title */
}

.card {
  background-color: #677D6A; /* Light Green for card background */
  border: none;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #D6BD98; /* Dark Green for card titles */
}

.card-text {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #1A3636; /* Dark Green for card text */
}

.btn-outline-primary {
  border-color: #1A3636; /* Dark Green for button border */
  color: #1A3636; /* Dark Green for button text */
}

.btn-outline-primary:hover {
  background-color: #1A3636; /* Dark Green background on hover */
  color: #D6BD98; /* Beige text on hover */
}

/* Rating Section Styling */
.rating-section {
  background-color: #677D6A; /* Light Green for rating section background */
  padding: 2rem;
  border-radius: 8px;
  margin-top: 2rem;
}
</style>
