<template>
    <div class="page-content">
      <div class="list-container">
        <div class="list-header">Closest Facilities</div>
        <ul class="result-list">
          <li
            v-for="(result, index) in nearbyResults"
            :key="index"
            class="result-list-item"
            @click="focusOnFacility(result)"
          >
            <h4>{{ result.name }}</h4>
            <p>{{ result.address }}</p>
          </li>
        </ul>
      </div>
      <div class="map-container">
  <h2>Find Elderly Home Facilities Near You</h2>
  <input
    type="text"
    v-model="searchQuery"
    placeholder="Search nearby (e.g., pharmacy, park)"
    @keyup.enter="searchNearbyPlaces"
    class="search-input"
  />
  <button @click="searchNearbyPlaces" class="btn-search">Search</button>
  <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  <div ref="mapContainer" class="map"></div>
</div>
    </div>
  </template>
  
  
  
  <script>
  import 'mapbox-gl/dist/mapbox-gl.css';
  import mapboxgl from 'mapbox-gl';
  import * as XLSX from 'xlsx';
  import axios from 'axios';

  export default {
    name: 'ElderlyHomeMap',
    data() {
      return {
        accessToken: 'pk.eyJ1IjoiZnJhbmNpc3lyYW4iLCJhIjoiY20yMnNkNnJrMDl2eTJycHNlYjllMW42ZiJ9.7B9m6kMVeyJ-TWMEhTxZCA',
        map: null,
        userLocation: null,
        facilities: [],
        errorMessage: '',
        searchQuery: '',
        markers: [],
        nearbyResults: [],
      };
    },
    methods: {
        async loadFacilitiesData() {
  try {
    const response = await axios.get('/VIC-Service-List-2023.xlsx', { responseType: 'arraybuffer' });
    const data = new Uint8Array(response.data);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    console.log('Loaded data:', jsonData);

    this.facilities = jsonData
      .map((entry) => ({
        name: entry['Aged Care Service List - VIC - as at 30 June 2023']?.trim() || 'Unnamed Facility',
        address: entry['__EMPTY']?.trim() || 'No Address Provided',
        provider: entry['__EMPTY_10']?.trim() || 'No Provider Name',
        latitude: parseFloat(entry['__EMPTY_24']),
        longitude: parseFloat(entry['__EMPTY_25']),
      }))
      .filter(
        (facility) =>
          !isNaN(facility.latitude) &&
          !isNaN(facility.longitude) &&
          facility.latitude >= -90 &&
          facility.latitude <= 90 &&
          facility.longitude >= -180 &&
          facility.longitude <= 180
      );

    if (this.facilities.length === 0) {
      this.errorMessage = 'No facility data available to find nearby locations.';
    } else {
      this.addFacilityMarkers();
      this.showClosestFacilities(); // Display closest facilities if data is loaded
    }
  } catch (error) {
    this.errorMessage = `Error loading facilities data: ${error.message}. Please check if the file is available and correctly formatted.`;
    console.error('Error loading facilities data:', error);
  }
},


      addFacilityMarkers() {
        this.facilities.forEach(facility => {
          const coordinates = [facility.longitude, facility.latitude];
          const description = `
            <h3>${facility.name}</h3>
            <p><strong>Address:</strong> ${facility.address}</p>
            <p><strong>Provider:</strong> ${facility.provider}</p>
          `;
          this.addMarker(coordinates, description);
        });
      },
      
      addMarker(coordinates, description, isUserLocation = false, isSearchResult = false, closestIndex = null) {
  const markerElement = document.createElement('div');

  // Determine the class for the marker based on its type
  markerElement.className = isUserLocation
    ? 'marker-user-location'
    : isSearchResult
    ? 'marker-search-result'
    : closestIndex !== null
    ? `marker-closest-facility-${closestIndex}`
    : 'marker';

  const popupContent = `
    ${description}
    ${!isUserLocation ? '<button class="btn-directions" onclick="window.dispatchEvent(new CustomEvent(\'get-directions\', { detail: { coordinates: [' + coordinates + '] } }))">Get Directions</button>' : ''}
  `;

  const marker = new mapboxgl.Marker(markerElement)
    .setLngLat(coordinates)
    .setPopup(new mapboxgl.Popup().setHTML(popupContent))
    .addTo(this.map);

  this.markers.push(marker);
},

getUserLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        this.userLocation = [longitude, latitude];
        this.initializeMap();
        this.showClosestFacilities(); // Show closest facilities after getting user location
      },
      (error) => {
        this.handleGeolocationError(error);
        this.initializeMap();
      },
      {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 0,
      }
    );
  } else {
    this.errorMessage = 'Geolocation is not supported by this browser.';
    this.initializeMap();
  }
},

handleGeolocationError(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      this.errorMessage = 'User denied the request for Geolocation. Please enable location services.';
      break;
    case error.POSITION_UNAVAILABLE:
      this.errorMessage = 'Location information is unavailable. Please try again later.';
      break;
    case error.TIMEOUT:
      this.errorMessage = 'The request to get your location timed out. Please try again.';
      break;
    default:
      this.errorMessage = 'An unknown error occurred while fetching your location.';
      break;
  }
  console.error('Geolocation error:', error);
},

      initializeMap() {
        mapboxgl.accessToken = this.accessToken;
        const center = this.userLocation || [144.9631, -37.8136]; // Default to Melbourne if no user location

        this.map = new mapboxgl.Map({
          container: this.$refs.mapContainer,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: center,
          zoom: 10,
        });

        this.map.on('load', () => {
          if (this.userLocation) {
            this.addMarker(this.userLocation, 'Your Location', true);
          }
          this.loadFacilitiesData();
        });
      },

      showClosestFacilities() {
  if (!this.userLocation) {
    this.errorMessage = 'User location is not available.';
    return;
  }

  if (!this.facilities || this.facilities.length === 0) {
    this.errorMessage = 'No facility data available to find nearby locations.';
    return;
  }

  // Sort the facilities by distance to the user location and get the top 5
  const sortedFacilities = this.facilities
    .sort((a, b) => this.getDistance(a.latitude, a.longitude) - this.getDistance(b.latitude, b.longitude))
    .slice(0, 5);

  // Store these facilities to display in the sidebar
  this.nearbyResults = sortedFacilities;

  // Add markers for the closest facilities with a different color for each index
  sortedFacilities.forEach((facility, index) => {
    const description = `
      <h3>${facility.name}</h3>
      <p><strong>Address:</strong> ${facility.address}</p>
      <p><strong>Provider:</strong> ${facility.provider}</p>
    `;
    this.addMarker([facility.longitude, facility.latitude], description, false, false, index);
  });

  // Clear the error message if facilities are successfully displayed
  this.errorMessage = '';
},



      getDistance(lat, lng) {
        const [userLng, userLat] = this.userLocation || [144.9631, -37.8136];
        const R = 6371; // Earth radius in km
        const dLat = (lat - userLat) * (Math.PI / 180);
        const dLng = (lng - userLng) * (Math.PI / 180);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(userLat * (Math.PI / 180)) * Math.cos(lat * (Math.PI / 180)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
      },

      async searchNearbyPlaces() {
  if (!this.searchQuery) {
    this.errorMessage = 'Please enter a search term.';
    return;
  }

  // Filter facilities based on the search query
  const matchedFacilities = this.facilities.filter((facility) => {
    const facilityName = facility.name ? facility.name.toLowerCase() : '';
    const facilitySuburb = facility.suburb ? facility.suburb.toLowerCase() : '';
    return (
      facilityName.includes(this.searchQuery.toLowerCase()) ||
      facilitySuburb.includes(this.searchQuery.toLowerCase())
    );
  });

  if (matchedFacilities.length > 0) {
    // Get the top 5 results
    const topResults = matchedFacilities.slice(0, 5);
    this.nearbyResults = topResults;

    // Fly to the location of the first matched facility
    const firstFacility = topResults[0];
    const coordinates = [firstFacility.longitude, firstFacility.latitude];

    this.map.flyTo({
      center: coordinates,
      zoom: 12,
    });

    // Add markers for the top search results with a different color
    topResults.forEach((facility) => {
      const facilityCoordinates = [facility.longitude, facility.latitude];
      const description = `
        <h3>${facility.name}</h3>
        <p><strong>Address:</strong> ${facility.address}, ${facility.suburb || 'N/A'}</p>
        <p><strong>Provider:</strong> ${facility.provider}</p>
      `;
      this.addMarker(facilityCoordinates, description, false, true); // Pass `true` for `isSearchResult` to change the pin color
    });

    // Reset the error message if the search is successful
    this.errorMessage = '';
  } else {
    this.errorMessage = 'No matching care facilities or suburbs found. Try a different search term.';
  }
},


      clearMarkers() {
        this.markers.forEach(marker => marker.remove());
        this.markers = [];
      },

      getDirectionsTo(destination) {
  if (!this.userLocation) {
    this.errorMessage = 'User location is not available yet.';
    return;
  }

  // Define URLs for both driving and walking directions
  const drivingUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${this.userLocation[0]},${this.userLocation[1]};${destination[0]},${destination[1]}?geometries=geojson&access_token=${this.accessToken}`;
  const walkingUrl = `https://api.mapbox.com/directions/v5/mapbox/walking/${this.userLocation[0]},${this.userLocation[1]};${destination[0]},${destination[1]}?geometries=geojson&access_token=${this.accessToken}`;

  // Use axios to fetch both directions
  axios.all([axios.get(drivingUrl), axios.get(walkingUrl)])
    .then(axios.spread((drivingResponse, walkingResponse) => {
      const drivingRoute = drivingResponse.data.routes[0];
      const walkingRoute = walkingResponse.data.routes[0];

      // Extract the route geometry and duration
      const drivingTime = Math.round(drivingRoute.duration / 60); // Convert seconds to minutes
      const walkingTime = Math.round(walkingRoute.duration / 60); // Convert seconds to minutes

      // Add the driving route to the map
      this.addRouteToMap(drivingRoute.geometry);

      // Display the estimated times
      this.errorMessage = `Estimated travel time: ${drivingTime} min by car, ${walkingTime} min on foot.`;
    }))
    .catch((error) => {
      this.errorMessage = `Error fetching directions: ${error.message}`;
      console.error('Error fetching directions:', error);
    });
},


      addRouteToMap(route) {
        if (this.map.getSource('route')) {
          if (this.map.getLayer('route')) {
            this.map.removeLayer('route');
          }
          this.map.removeSource('route');
        }

        this.map.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: route,
          },
        });

        this.map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#007bff',
            'line-width': 5,
          },
        });
    },
    focusOnFacility(facility) {
  const coordinates = [facility.longitude, facility.latitude];
  
  // Fly to the selected facility's location on the map.
  this.map.flyTo({
    center: coordinates,
    zoom: 12,
  });

  const description = `
    <h3>${facility.name}</h3>
    <p><strong>Address:</strong> ${facility.address}, ${facility.suburb || 'N/A'}</p>
    <p><strong>Provider:</strong> ${facility.provider}</p>
  `;

  // Add a marker using the same style as `.marker-search-result`.
  this.addMarker(coordinates, description, false, true); // Pass `true` for `isSearchResult`
},
    },

    mounted() {
      this.getUserLocation(); // This will fetch the location and then initialize the map
      window.addEventListener('get-directions', (event) => {
        const destination = event.detail.coordinates;
        this.getDirectionsTo(destination);
      });
    },
  };
</script>


<style>
.map-container {
  width: 100%;
  height: 500px;
}

.marker {
  background-color: #007bff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.marker-search-result {
  background-color: #ffbf00; /* A different color for searched results, e.g., yellow */
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.marker-user-location {
  background-color: #ff0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.error-message {
  color: red;
}

.marker-closest-facility-0 {
  background-color: #ffbf00; /* Red */
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.marker-closest-facility-1 {
  background-color: #ffbf00; /* Green */
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.marker-closest-facility-2 {
  background-color: #ffbf00; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.marker-closest-facility-3 {
  background-color: #ffbf00; /* Yellow */
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.marker-closest-facility-4 {
  background-color: #ffbf00; /* Magenta */
  border-radius: 50%;
  width: 20px;
  height: 20px;
}


</style>

  


<style scoped>
.page-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
}

.info-section {
  text-align: center;
  margin-bottom: 20px;
}

.info-section h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.info-section p {
  font-size: 16px;
  color: #555;
  max-width: 600px;
  margin: 0 auto;
}

.list-container {
  flex: 0 0 300px;
  max-width: 300px;
  background-color: #f7f7f7;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.list-header {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.result-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 400px;
  overflow-y: auto;
}

.result-list-item {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.result-list-item:hover {
  background-color: #0056b3;
}

.result-list-item h4 {
  margin: 0 0 5px 0;
  font-size: 14px;
  color: #fff;
}

.result-list-item p {
  margin: 0;
  font-size: 12px;
  color: #d1d1d1;
}

.map-container {
  flex: 1;
  height: 500px;
  margin: 20px 0;
}

.map {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.error-message {
  color: red;
  margin: 10px 0;
  text-align: center;
}

.search-input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.btn-search {
  padding: 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: 10px;
}

.btn-search:hover {
  background-color: #0056b3;
}

.btn-directions {
  background-color: #007bff;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 5px;
}

.btn-directions:hover {
  background-color: #0056b3;
}

.marker {
  background-color: #007bff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.marker-user-location {
  background-color: #ff0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
</style>
