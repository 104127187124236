<!-- src/views/AboutUs.vue -->
<template>
  <div class="about-us">
    <!-- Hero Section with Background Image -->
    <section class="hero-section position-relative" data-aos="fade-in">
      <img src="@/assets/AboutUs.jpg" alt="About Us Background" class="hero-image" />
      <div class="hero-overlay">
        <div class="container text-center text-white">
          <h1 class="hero-title" data-aos="fade-up">About Jade Wellness Hub</h1>
          <p class="hero-subtitle" data-aos="fade-up" data-aos-delay="200">
            Dedicated to improving the health and well-being of the elderly community
          </p>
        </div>
      </div>
    </section>

    <!-- Mission Section -->
    <section class="mission-section text-center py-4" data-aos="fade-right">
      <div class="container">
        <img src="@/assets/JADE.png" alt="Jade Icon" class="section-icon mb-3" />
        <h2 class="section-title">Our Mission</h2>
        <p class="section-text">
          At Jade Wellness Hub, our mission is to improve the health and well-being of the elderly population by providing accessible health resources, community support, and education.
        </p>
      </div>
    </section>

    <!-- Vision Section -->
    <section class="vision-section text-center py-4" data-aos="fade-left">
      <div class="container">
        <img src="@/assets/JADE.png" alt="Jade Icon" class="section-icon mb-3" />
        <h2 class="section-title">Our Vision</h2>
        <p class="section-text">
          We envision a world where every elderly individual has access to the resources and support needed to live a healthy, fulfilling life. We aim to empower communities through innovative technology solutions and personalized care.
        </p>
      </div>
    </section>

    <!-- Values Section -->
    <section class="values-section py-4" data-aos="fade-up">
      <div class="container">
        <img src="@/assets/JADE.png" alt="Jade Icon" class="section-icon mb-3" />
        <h2 class="section-title text-center">Our Core Values</h2>
        <div class="row mt-4">
          <div class="col-md-4 mb-4" data-aos="zoom-in">
            <div class="card bg-light-green text-dark-green shadow-sm">
              <div class="card-body">
                <h5 class="card-title">Compassion</h5>
                <p class="card-text">
                  We approach every individual with empathy, understanding, and a commitment to provide the best care possible.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="card bg-light-green text-dark-green shadow-sm">
              <div class="card-body">
                <h5 class="card-title">Innovation</h5>
                <p class="card-text">
                  We strive to leverage technology and innovative solutions to enhance the quality of life for our community members.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-4" data-aos="zoom-in" data-aos-delay="200">
            <div class="card bg-light-green text-dark-green shadow-sm">
              <div class="card-body">
                <h5 class="card-title">Inclusivity</h5>
                <p class="card-text">
                  We are dedicated to serving a diverse community, ensuring that everyone feels valued and supported.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Services Section -->
    <section class="services-section py-4" data-aos="fade-up">
      <div class="container">
        <img src="@/assets/JADE.png" alt="Jade Icon" class="section-icon mb-3" />
        <h2 class="section-title text-center">What We Offer</h2>
        <div class="row mt-4">
          <div class="col-md-6 mb-4" data-aos="fade-up" data-aos-delay="100">
            <div class="card bg-soft-green text-dark-green shadow-sm">
              <div class="card-body">
                <h5 class="card-title">Health Education</h5>
                <p class="card-text">
                  Providing educational resources to help the elderly understand and manage their health better.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4" data-aos="fade-up" data-aos-delay="200">
            <div class="card bg-soft-green text-dark-green shadow-sm">
              <div class="card-body">
                <h5 class="card-title">Community Support</h5>
                <p class="card-text">
                  Facilitating support groups and community activities that encourage social engagement and well-being.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'AboutUs',
  mounted() {
    AOS.init({
      duration: 1000, // Animation duration (1000ms = 1s)
      offset: 200, // Offset for animations (triggers animations sooner or later based on scroll)
      easing: 'ease-in-out', // Easing for animations
    });
  },
};
</script>

<style scoped>
/* Common Animation Transitions */
.section-title, .section-text, .card-title, .card-text {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

/* Hero Section Styling */
.hero-section {
  position: relative;
  width: 100%;
  height: 60vh; /* Adjust height as needed */
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the hero section without distortion */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(64, 81, 59, 0.6); /* Semi-transparent dark green overlay for text contrast */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  color: #EDF1D6; /* Light Green for text */
}

.hero-subtitle {
  font-size: 1.25rem;
  color: #EDF1D6; /* Light Green for text */
}

/* Section Styling */
.about-us {
  background-color: #EDF1D6; /* Light Green background */
  color: #40513B; /* Dark Green text color */
}

.section-icon {
  width: 60px; /* Adjust size for section icons */
  height: auto;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  color: #40513B; /* Dark Green for titles */
}

.section-text {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #40513B; /* Dark Green for text */
}

.card {
  background-color: #9DC08B; /* Soft Green for card background */
  border: none;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #40513B; /* Dark Green for card titles */
}

.card-text {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #40513B; /* Dark Green for card text */
}
</style>
