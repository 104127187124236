<template>
  <div class="rating-container">
    <h3>Rate this item:</h3>
    <div v-if="!isAuthenticated">
      <p class="login-prompt text-warning">
        Please <router-link to="/login">log in</router-link> to rate this item.
      </p>
    </div>
    <div v-else>
      <div class="rating-stars">
        <span
          v-for="star in 5"
          :key="star"
          @click="submitRating(star)"
          @mouseover="hoverRating(star)"
          @mouseleave="hoverRating(0)"
          :class="{ active: star <= currentRating, hovered: star <= hoveredRating }"
        >
          ★
        </span>
      </div>
      <div class="average-rating-container">
        <p class="average-rating">Average Rating:</p>
        <div class="star-display">
          <span
            v-for="star in 5"
            :key="star"
            :class="{ filled: star <= averageRating, empty: star > averageRating }"
          >
            ★
          </span>
          <span class="rating-value">{{ averageRating.toFixed(1) }} / 5</span>
        </div>
      </div>
      <p v-if="userRating" class="user-feedback">
        You rated this item {{ userRating }} stars.
      </p>
      <p v-if="hasRated" class="text-warning">You have already rated this item.</p>
    </div>
  </div>
</template>

<script>
import { onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc, collection, getDocs, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';

export default {
  data() {
    return {
      currentRating: 0,
      hoveredRating: 0,
      userRating: 0,
      ratings: [],
      hasRated: false,
      totalRatings: 0,
      averageRating: 0,
      userEmail: null,
    };
  },
  computed: {
    isAuthenticated() {
      return !!this.userEmail;
    },
  },
  mounted() {
    this.setupAuthListener();
  },
  methods: {
    setupAuthListener() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.userEmail = user.email;
          this.loadRatings();
          this.checkIfUserHasRated();
          this.listenForRatingChanges();
        } else {
          this.userEmail = null;
        }
      });
    },
    async submitRating(star) {
      if (this.hasRated || !this.isAuthenticated) return;

      if (!this.userEmail) {
        console.error('User email is undefined. Cannot submit rating.');
        return;
      }

      this.currentRating = star;
      this.userRating = star;

      try {
        await setDoc(doc(db, 'ratings', this.userEmail), {
          email: this.userEmail,
          rating: star,
          timestamp: new Date(),
        });

        this.hasRated = true;
        this.loadRatings(); // Refresh ratings after submission
      } catch (error) {
        console.error('Error saving rating:', error);
      }
    },
    hoverRating(star) {
      if (!this.hasRated) {
        this.hoveredRating = star;
      }
    },
    async loadRatings() {
      try {
        const ratingsSnapshot = await getDocs(collection(db, 'ratings'));
        this.ratings = ratingsSnapshot.docs.map((doc) => doc.data());
        this.calculateAverageRating();
      } catch (error) {
        console.error('Error loading ratings:', error);
      }
    },
    calculateAverageRating() {
      this.totalRatings = this.ratings.length;
      if (this.totalRatings > 0) {
        const total = this.ratings.reduce((sum, rating) => sum + (rating.rating || 0), 0);
        this.averageRating = total / this.totalRatings;
      } else {
        this.averageRating = 0;
      }
    },
    async checkIfUserHasRated() {
      if (!this.userEmail) {
        console.error('User email is undefined. Cannot check if user has rated.');
        return;
      }

      try {
        const userRatingDoc = await getDoc(doc(db, 'ratings', this.userEmail));
        if (userRatingDoc.exists()) {
          this.hasRated = true;
          const userRatingData = userRatingDoc.data();
          this.userRating = userRatingData.rating;
          this.currentRating = userRatingData.rating;
        } else {
          this.hasRated = false;
        }
      } catch (error) {
        console.error('Error checking user rating:', error);
      }
    },
    listenForRatingChanges() {
      const ratingsCollection = collection(db, 'ratings');
      onSnapshot(ratingsCollection, (snapshot) => {
        this.ratings = snapshot.docs.map((doc) => doc.data());
        this.calculateAverageRating();
      });
    },
  },
};
</script>


<style scoped>
.rating-container {
  padding: 1rem;
  background-color: #EDF1D6; /* Light Green background */
  border-radius: 8px;
  text-align: center;
  margin: 1rem 0;
}

.rating-stars {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.rating-stars span {
  font-size: 2rem;
  cursor: pointer;
  color: #9DC08B; /* Soft Green for inactive stars */
  transition: color 0.3s ease;
}

.rating-stars span.active {
  color: #40513B; /* Dark Green for selected stars */
}

.rating-stars span.hovered {
  color: #609966; /* Medium Green for hovered stars */
}

.average-rating-container {
  margin-top: 1rem;
  text-align: center;
}

.star-display {
  display: flex;
  justify-content: center;
  align-items: center;
}

.star-display span {
  font-size: 1.5rem;
  color: #9DC08B; /* Soft Green for inactive stars */
  margin-right: 5px;
}

.star-display span.filled {
  color: #40513B; /* Dark Green for filled stars */
}

.star-display span.empty {
  color: #9DC08B; /* Soft Green for empty stars */
}

.rating-value {
  font-size: 1.2rem;
  color: #40513B; /* Dark Green */
  margin-left: 10px;
}

.user-feedback {
  font-size: 1rem;
  color: #609966; /* Medium Green */
}

.text-warning {
  color: #FFAA00; /* Warning color for already rated message */
}

.login-prompt {
  font-size: 1rem;
  color: #FFAA00; /* Warning color for login prompt */
}
</style>
